import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { propertyService } from "../../_services";
import "react-confirm-alert/src/react-confirm-alert.css";
import Footer from "./../../layout/footer";
import Pagination from "react-js-pagination";
import CustomModal from "../ReactModal";
import { AiOutlinePlus } from "react-icons/ai";
import StarIcon from "../../images/svg/star.svg";
import StarFilledIcon from "../../images/svg/star-filled.svg";
import { Wrapper } from "../Dashboard/Wrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UnlistPropertyModal from "./UnlistPropertyModal";

export default function propertyList(props) {
  window.scrollTo({ top: 0, behavior: "smooth" });
  const [requesting, setRequesting] = useState(true);
  const [list, setList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [search, setSearch] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [resetModal, setResetModal] = useState(false);
  const [rejectRequesting, setRejectRequesting] = useState(false);
  const reasonRef = useRef();
  const [selectedState, setSetselectedState] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState('');
  const [sort, setSort] = useState(0)
  const [isSort, setIsSort] = useState(0)
  const [openUnlistPropertyDialog, setOpenUnlistPropertyDialog] = useState({open:false,status:null})
  const [propertyIdModal, setPropertyIdModal] = useState(null);
  
  
  var limit = 10;

  const available = []; // list.filter((d) => d.status === "Available");
  const booked = []; //list.filter((d) => d.status === "Booked");
  const [suspense, setSuspense] = useState(false);
  const toggleSuspense = () => {
    setSuspense((e) => !e);
  };
  const togglePropertyStatus = async (status,reason) => {
    toggleSuspense();
    handleStatus(propertyIdModal, status, toggleSuspense,reason);
  };
  const handleUnlistConfirm = (reason,status) => { 
    togglePropertyStatus(status,reason)
     console.log('property unlist confirmed',propertyIdModal)
   }

  const handleStatus = (id, status , cb,reason) => {
  
    if(status == "Unlisted"){
      reason = reason;
    }
    else{
      reason = null ;
    }
    if (status == "Rejected") {
      setPropertyId(id);
      toggleRejectReasonPropertyModal();
    } else {
      propertyService.updateStatus({ propertyId: id, status: status ,reason:reason }).then(
        (objS) => {
          if (objS.status) {
            setRefreshKey((oldKey) => oldKey + 1);
            if (status == "Listed") {
              toast.success("Property Listed");
            } else if (status == "Unlisted") {
              toast.success("Property unlisted");
            }
          } else {
            toast.error(objS.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const getSortIcon = () => {
    if (isSort == 1 && sort == 1) {
        return "▲"; // Ascending
    } else if (isSort == 1 && sort == 0) {
        return "▼"; // Descending
    }
    return "⬍"; // No sort
    };

  const rejectProperty = (e) => {
    if (!reasonRef.current.value) return;

    setRejectRequesting(true);
    propertyService
      .updateStatus({
        propertyId,
        status: "Rejected",
        rejectReason: reasonRef.current.value,
      })
      .then(
        (objS) => {
          if (objS.status) {
            setPropertyId("");
            toggleRejectReasonPropertyModal();
            toast.success("Property rejected");
            setRefreshKey((oldKey) => oldKey + 1);
          }
          setRejectRequesting(false);
        },
        (error) => {
          console.log(error);
          setRejectRequesting(false);
        }
      );
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };
  const handleSort = (e) => {
    setSort(!sort)
    setIsSort(1)
    console.log(sort, "---sort---" , isSort, "----isSort----")  
    setPage(1);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const toggleRejectReasonPropertyModal = () => {
    setResetModal(!resetModal);
  };

  const getBedCount = (Arrangements) => {
    if (!Arrangements || !Arrangements.length) return 0;
    return Arrangements.reduce(function(acc, obj) {
      return acc + parseInt(obj.numberOfBeds);
    }, 0);
  };

  const pageChange = (page) => {
    setPage(page);
    setRequesting(true);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const sendMail = (data) => {
    propertyService.sendPaymentReminderMail({ hostId: data.hostId.id }).then(
      (objS) => {
        toast(objS.message);
      },
      (error) => {
        console.log(error);
      }
    );
  };
  // useEffect(() => {
  //   getListing(search, selectedState,selectedStatus ,isSort,sort );
   
  // }, [refreshKey, selectedState, selectedStatus  ]);

  // const getListing = useCallback((search, selectedState,selectedStatus ,isSort,sort ) => {
  //   console.log(search, selectedState,selectedStatus ,isSort,sort , "getcall-------")
  //   propertyService.list({ page, limit: 10, search, selectedState,selectedStatus ,isSort,sort }).then(
  //     (objS) => {
  //       setList(objS.data.data);
  //       console.log("setlist---", objS.data.data);
  //       setRequesting(false);
  //       setTotal(objS.data.total);
  //     },
  //     (error) => {
  //       console.log("+++++++++++++++++++++++++++++++++++", error);
  //       console.log(error);
  //     }
  //   );

  // },[]);



  
  useEffect(() => {
  const propertyAPIHandler = async () => {
    setRequesting(true);

    try {
      const { data } = await propertyService.list({
        page,
        limit: 10,
        search,
        selectedState,
        selectedStatus,
        isSort,
        sort,
      });
      console.log(data, "data-----------------");
      setList(data.data );
      setTotal(data.total );
    } catch (err) {
      console.log(err);
    }
   finally {
    setRequesting(false);
  }
   // setRequesting(false);
  };
  propertyAPIHandler();
}, [refreshKey, selectedState, selectedStatus ,isSort , sort]);


  const handleFeatured = async (property) => {
    try {
      const result = await propertyService.handleFeaturedService(property.id);
      console.log(result, "resddd");
      toast(result.data);
      setRefreshKey((oldKey) => oldKey + 1);
    } catch (error) {
      toast("something went wrong");
      console.log("error in handleFeatured", error);
    }
  };

  const exportProperties = async () => {
    await propertyService.exportProperties(search, selectedState);
  };

  const handleSearchByStatus = (text) => {
    console.log(text) 
    setSelectedStatus(text)
   }
   

  return (
    <Wrapper
      showSearch={true}
      showDownload={true}
      handleSearch={handleSearch}
      title="Property List"
      search={search}
      exportProperties={exportProperties}
      handleSearchByState={(e) => {
        setSetselectedState(e);
        setPage(1);
      }}
      handleSearchByStatus={handleSearchByStatus}
      showFilterPropertyByState={true}
    >

      <UnlistPropertyModal
        isOpen={openUnlistPropertyDialog.open}
        status={openUnlistPropertyDialog.status}
        onClose={()=>setOpenUnlistPropertyDialog({open:false,status:null})}
        onConfirm={handleUnlistConfirm}
        propertyId={propertyIdModal}
      />  
      <ToastContainer />
      <div className="row">
        {requesting && (
          <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
            <div className="bg-white tw-w-max rounded shadow-md p-4">
              <div className="loader"></div>
            </div>
          </div>
        )}
        <div className="col-xl-12">
          <div className="tab-content">
            <div className="tab-pane active show" id="All">
              <div className="table-responsive">
                <table
                  className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                  id="guestTable-all"
                >
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Payout Verification Status</th>
                      <th>Listing Id</th>
                      <th>Listing Name</th>
                      <th>Host Name</th>
                      <th>Address</th>
                      <th>Rental Type</th>
                      <th>Accommodates</th>
                      <th>Beds Listed</th>
                      <th>Property Type</th>
                      <th>Booking Type</th>
                      <th>Covid Verified</th>
                      <th>Reserve Stay Limit</th>
                      <th>Lineholder Stay Limit</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th style={{ cursor: "pointer" }} onClick={handleSort}>Last Logged In {getSortIcon()} </th>
                      <th>Reject Reason</th>
                      <th>View Count</th>
                      <th>Approved Count</th>
                      <th>Denied Count</th>
                      <th>Expired Count</th>
                      <th>Rating</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!requesting &&  list.map((property ,index) => (
                      <tr key={`${property.id}-${index}`}>
                        <td>
                          <div className="tw-flex tw-items-center">
                            {property.status == "Listed" && (
                              <div
                                onClick={() => handleFeatured(property)}
                                className="tw-cursor-pointer"
                              >
                                {property.isFeatured ? (
                                  <img
                                    src={StarFilledIcon}
                                    alt="favourite"
                                    className="tw-w-10 tw-h-10"
                                  />
                                ) : (
                                  <img
                                    src={StarIcon}
                                    alt="favourite"
                                    className="tw-w-10 tw-h-10"
                                  />
                                )}
                              </div>
                            )}
                            <ul className=" tw-flex tw-flex-col tw-space-y-1 tw-p-2 tw-m-4">
                              {property.status != "Draft" && (
                                <Link to={"/properties/edit/" + property.id}>
                                  <li className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white">
                                    Edit
                                  </li>
                                </Link>
                              )}
                              {property.status == "Draft" && (
                                <Link
                                  className="tw-block tw-p-2"
                                  to={"/properties/add/" + property.id}
                                >
                                  <li className="tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white">
                                    Update Draft
                                  </li>
                                </Link>
                              )}
                              {property.status == "Pending" && (
                                <li className="tw-cursor-pointer tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white">
                                  <a
                                    id={property.id}
                                    key={property.id}
                                    onClick={(e) => {
                                      handleStatus(e.target.id, "Listed");
                                    }}
                                  >
                                    Approve
                                  </a>
                                </li>
                              )}
                              {property.status == "Pending" && (
                                <li className="tw-cursor-pointer tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white">
                                  <a
                                    id={property.id}
                                    key={property.id}
                                    onClick={(e) => {
                                      handleStatus(e.target.id, "Rejected");
                                    }}
                                  >
                                    Reject
                                  </a>
                                </li>
                              )}
                              {property.status == "Listed" && (
                                <>
                                  <li className="tw-cursor-pointer tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white">
                                    <a
                                      id={property.id}
                                      key={property.id}
                                      // onClick={(e) => {
                                      //   handleStatus(property._id, "Unlisted");
                                      // }}
                                      onClick={(e) => {
                                        setPropertyIdModal(property._id)
                                        setOpenUnlistPropertyDialog({open:true,status:"Unlisted" })
                                      }}
                                    >
                                      Unlist
                                    </a>
                                  </li>
                                </>
                              )}
                              {property.status == "Unlisted" && (
                                <li className="tw-cursor-pointer tw-text-center  tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-rounded-md tw-text-white">
                                  <a
                                    id={property.id}
                                    key={property.id}
                                    onClick={(e) => {
                                      handleStatus(property._id, "Listed");
                                    }}
                                  >
                                    List
                                  </a>
                                </li>
                              )}
                              {/* {property.status == "Pending" && (
                                          <li class="tw-text-sm tw-text-gray-700">
                                            <a
                                              class="tw-block tw-p-2"
                                              id={property.id}
                                              key={property.id}
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                handleStatus(
                                                  e.target.id,
                                                  "Listed"
                                                );
                                              }}
                                            >
                                              Approve
                                            </a>
                                          </li>
                                        )} */}
                              {/* {property.status == "Pending" && (
                                          <li class="tw-text-sm tw-text-gray-700">
                                            <a
                                              class="tw-block tw-p-2"
                                              id={property.id}
                                              key={property.id}
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                handleStatus(
                                                  e.target.id,
                                                  "Rejected"
                                                );
                                              }}
                                            >
                                              Reject
                                            </a>
                                          </li>
                                        )} */}
                            </ul>
                          </div>
                        </td>
                        <td>
                          <span className="fs-16">
                            {property.isPayoutMethodEnabled ? (
                              "Verified"
                            ) : (
                              <button
                                className="btn btn-primary"
                                onClick={() => sendMail(property)}
                              >
                                Send Mail
                              </button>
                            )}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">{property.listingId} </span>
                        </td>
                        <td>
                          <span className="fs-16">{property.listingName} </span>
                        </td>
                        <td>
                          <span className="fs-16">
                            {property.hostId && property.hostId.name}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">{property.address} </span>
                        </td>
                        <td>
                          <span className="fs-16">{property.rentalType} </span>
                        </td>
                        <td>
                          <span className="fs-16">
                            {property.accomodationCapacity}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">
                            {getBedCount(property.sleepingArrangements)}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">
                            {property.propertyType}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">{property.bookingType} </span>
                        </td>

                        <td>
                          <span className="fs-16">
                            {property.covidVerified}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">
                            {property.reservedDays
                              ? property.reservedDays
                              : "--"}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">
                            {property.lineholderDays
                              ? property.lineholderDays
                              : "--"}{" "}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">{property.status} </span>
                        </td>
                        <td>
                          <span className="fs-16">
                            {moment(property.createdAt).format("MMMM Do, YYYY")}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">
                            {moment(property.updatedAt).format("MMMM Do, YYYY")}
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">
                             { property.hostId && property.hostId.userSession[0] ? moment(property.hostId.userSession[0].updatedAt).format("MMMM Do, YYYY hh:mm a") : ""} 
                            
                          </span>
                        </td>
                        <td>
                          <span className="fs-16">{property.rejectReason}</span>
                        </td>
                        <td>
                          <span className="fs-16">-- </span>
                        </td>
                        <td>
                          <span className="fs-16">-- </span>
                        </td>
                        <td>
                          <span className="fs-16">-- </span>
                        </td>
                        <td>
                          <span className="fs-16">-- </span>
                        </td>
                        <td>
                          <span className="fs-16">-- </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        activePage={page}
        itemsCountPerPage={limit}
        totalItemsCount={total}
        pageRangeDisplayed={10}
        onChange={pageChange}
      />
      <Footer />
      {resetModal && (
        <CustomModal>
          <div className="tw-w-[85%] tw-mx-auto ">
            <span
              onClick={toggleRejectReasonPropertyModal}
              className="tw-rounded-full tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-bg-white tw-p-2 tw-block tw-ml-auto tw-text-gray-700 tw-h-12 tw-w-12"
            >
              <AiOutlinePlus size={22} className="tw-transform tw-rotate-45" />
            </span>
          </div>
          <div className="tw-bg-white tw-w-[75%] tw-mx-auto tw-p-10 tw-rounded-3xl tw-border-2 ">
            <div>
              <h3 className="tw-text-lg tw-text-center tw-font-medium tw-text-gray-700">
                Reject Reason
              </h3>
            </div>
            <div className="col-lg-12">
              <input
                ref={reasonRef}
                type="text"
                name="rejectReason"
                className="form-control"
                placeholder="Reject Reason"
              />
            </div>

            <div className="tw-text-sm tw-text-center">
              <button
                onClick={() => {
                  rejectProperty();
                }}
                disabled={rejectRequesting}
                // data-bs-dismiss="modal"
                className="mt-4 tw-w-max tw-text-center tw-mx-auto tw-col-span-full tw-text-sm tw-p-2 tw-px-4 tw-bg-primary tw-rounded-md tw-border-0 tw-text-white "
              >
                {rejectRequesting ? "Please wait.." : "Update"}
              </button>
            </div>
          </div>
        </CustomModal>
      )}
    </Wrapper>
  );
}
