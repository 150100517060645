import React, { useCallback, useEffect, useState } from "react";
import constant from "./../../_config/constant";
import { processMediaUrl } from "../../_helpers/utils";

function ChatCard({ onSelectChannel, data, activeChannel, setViewList }) {
  if (data.channelId == null || data.property == null) return null;

  const [unreadCount, setUnreadCount] = useState(data.unreadMessage);
  let activeClassname = "";
  // let cardName = `${data.property.name}-${data.property.user}-${data.property.host}`;
  let cardName = `${data.property.name}`;

  if (activeChannel && activeChannel.channelId === data.channelId) {
    activeClassname = " active-chat";
  }

  return (
    <div
      onClick={() => {
        setUnreadCount(0);
        onSelectChannel({
          name: data.name,
          channelId: data.channelId,
          user: data.user,
          host: data.host,
          property: data.property,
        });
        setViewList(true);
      }}
      className={`tw-flex tw-justify-between top-space-chat tw-items-center tw-cursor-pointer  tw-overflow-x-hidden  tw-p-4 hover-background  tw-text-primary tw-space-x-2 tw-rounded  ${activeClassname}`}
    >
      <div className="tw-flex tw-items-center tw-justify-start tw-space-x-2">
        <span className="tw-relative  tw-flex-shrink-0">
          <img
            alt=""
            src={processMediaUrl(data.property.picture)}
            className="tw-h-11 tw-w-11 tw-rounded-full tw-object-cover"
          />
          {/* <span
            className="tw-block tw-absolute border border-white  tw-rounded"
            style={{
              right: "3px",
              bottom: "3px",
              backgroundColor: "#06d6a0",
              height: "8px",
              width: "8px",
            }}
          ></span> */}
          {Boolean(unreadCount) && (
            <div className="tw-h-4 tw-w-4 tw-grid tw-place-content-center tw-text-xs tw-text-white tw-rounded-full tw-bg-[#e6ebf5] tw-absolute tw-top-0 tw-right-0 ">
              {unreadCount}
            </div>
          )}
        </span>
        <div className="tw-overflow-hidden">
          <p className="tw-text-xs tw-font-medium tw-mb-1 tw-block tw-truncate">
            {cardName.length <= 35 && cardName}
            {cardName.length > 35 && cardName.slice(0, 35) + "..."}
          </p>

          <p className="tw-text-xs tw-font-light tw-mb-0">
            Host: {data.property.host}
          </p>
          <p className="tw-text-xs tw-font-light tw-mb-0">
            User: {data.property.user}
          </p>

          <p className="tw-text-xs tw-font-light tw-mb-0">
            {data.lastMessageText}
          </p>
          {data.property.reservationId && (
            <p className="tw-text-xs tw-font-light tw-mb-0">
              {data.property.reservationId}
            </p>
          )}
        </div>
      </div>
      <p className="tw-text-xs">
        {data.invited && <p className="tw-text-primary ">New</p>}
      </p>
    </div>
  );
}

function ChatList({
  onSelectChannel,
  activeChannel,
  client,
  viewList,
  setViewList,
  refreshChatlist,
  setRefreshChatlist,
  onChannelsLoaded,
}) {
  const [channelUserStatuses, setChannelUserStatuses] = useState({});
  const [pagination, setPagination] = useState({
    nextPageFunc: null,
    prevPageFunc: null,
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [channels, setChannels] = useState({
    list: [],
    filtered: [],
    search: "",
  });
  const [loadingState, setLoadingState] = useState({
    initial: true,
    updating: false,
  });
  const [receivingMessages, setReceivingMessages] = useState(new Set()); // Track channels receiving messages
  const [allChannelsLoaded, setAllChannelsLoaded] = useState(false);
  const data = localStorage.getItem(constant.DATA_KEY);
  const user = data ? JSON.parse(data) : {};

  const loadPage = async (pageFunc) => {
    if (pageFunc) await pageFunc();
  };

  const fetchUserChannels = useCallback(
    async (isInitial = false) => {
      try {
        if (isInitial) {
          setLoadingState({ initial: true, updating: false });
        } else {
          setLoadingState((prev) => ({ ...prev, updating: true }));
        }
        const list =
          client &&
          client.current &&
          (await client.current.getPublicChannelDescriptors());
        setPagination({
          hasNextPage: list.hasNextPage,
          hasPrevPage: list.hasPrevPage,
          nextPageFunc: list.hasNextPage ? list.state.nextToken : null,
          prevPageFunc: list.hasPrevPage ? list.state.prevToken : null,
        });
        if (!list.state.items.length) {
          console.log("No channels found."); // Debug log
        }

        const channelsData = await Promise.all(
          list.state.items.map(async (curr) => {
            try {
              const channel = await client.current.getChannelBySid(curr.sid);
              const messages = await channel.getMessages();

              const receivedMessages = messages.items.filter(
                (msg) => msg.author !== user.id
              );
              const lastMessage = receivedMessages.length
                ? receivedMessages[receivedMessages.length - 1]
                : null;

              const members = await channel.getMembers();
              const users = {};

              for (const member of members) {
                const userId = member.identity;
                const userStatus = await client.current.getUser(userId);
                users[userId] = userStatus.state;

                if (userId !== user.id.toString()) {
                  users["displayState"] = userStatus.state.online;
                }
              }

              return {
                channelId: curr.uniqueName,
                name: curr.friendlyName,
                property: curr.attributes.property,
                unreadMessages: curr.descriptor.unread_messages_count,
                lastMessageText: lastMessage ? lastMessage.body : "",
                lastMessageDate: lastMessage ? lastMessage.dateCreated : null,
                users,
                invited: curr.descriptor.status === "invited" ? true : false,
              };
            } catch (error) {
              // console.error('Error processing channel:', curr.sid, error)

              // Option 1: Skip the channel and continue
              // return null;

              // Option 2: Return a placeholder or default data
              return {
                channelId: curr.uniqueName,
                name: curr.friendlyName || "Unknown Channel",
                property: curr.attributes.property || {},
                unreadMessages: 0,
                lastMessageText: "",
                lastMessageDate: null,
                users: {},
                invited: curr.descriptor.status === "invited" ? true : false,
              };
            }
          })
        );
        const userChannels = channelsData.sort(
          (a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate)
        );

        setChannels({ list: userChannels, filtered: userChannels, search: "" });
        setLoadingState({ initial: false, updating: false });
        setAllChannelsLoaded(true); // Mark all channels as loaded

        // Notify parent component that channels are loaded
        if (onChannelsLoaded) {
          onChannelsLoaded(userChannels);
        }

        // Set initial user statuses
        setChannelUserStatuses(
          userChannels.reduce((acc, channel) => {
            acc[channel.channelId] = channel.users;
            return acc;
          }, {})
        );
      } catch (error) {
        console.error("Error fetching channels:", error);
        setLoadingState({ initial: false, updating: false });
      }
    },
    [client, user.id, setRefreshChatlist, onChannelsLoaded]
  );

  const updateUnreadCount = useCallback((channelId, count) => {
    setChannels((prev) => {
      const updatedList = prev.list.map((channel) =>
        channel.channelId === channelId
          ? { ...channel, unreadMessages: count }
          : channel
      );
      const filteredList = updatedList.filter(
        (channel) =>
          channel.property.name
            .toUpperCase()
            .includes(prev.search.toUpperCase()) ||
          channel.property.host
            .toUpperCase()
            .includes(prev.search.toUpperCase()) ||
          channel.property.reservationId
            .toUpperCase()
            .includes(prev.search.toUpperCase())
      );
      return { ...prev, list: updatedList, filtered: filteredList };
    });
  }, []);

  useEffect(() => {
    fetchUserChannels(true); // Initial load
  }, [fetchUserChannels]);

  useEffect(() => {
    if (!loadingState.initial) {
      fetchUserChannels(); // Subsequent updates
    }
  }, [fetchUserChannels, refreshChatlist, loadingState.initial]);

  useEffect(() => {
    if (receivingMessages.size > 0) {
      setLoadingState((prev) => ({ ...prev, updating: true }));
    } else {
      setLoadingState((prev) => ({ ...prev, updating: false }));
    }
  }, [receivingMessages]);

  function handleSearchFilter(text) {
    setChannels(function(prev) {
      // Filter the list based on the search text
      var filteredChannels = prev.list.filter(function(channel) {
        var property = channel.property || {};
        var combinedString =
          (property.name || "") +
          "-" +
          (property.user || "") +
          "-" +
          (property.host || "") +
          "-" +
          (property.reservationId || "");

        // Check if the combined string contains the search text (case insensitive)
        return combinedString.toUpperCase().indexOf(text.toUpperCase()) !== -1;
      });

      // Return the updated state with the search text and filtered channels
      return {
        // Keep other properties from the previous state
        ...prev,
        search: text,
        filtered: filteredChannels,
      };
    });
  }

  return (
    <div
      className={`tw-flex tw-flex-col md:tw-col-span-2 tw-rounded-md tw-text-sm tw-z-[2]  tw-max-h-full tw-top-0 tw-bottom-0 tw-right-0 tw-left-0 tw-absolute md:tw-static tw-transform md:tw-transform-none tw-transition-all tw-overflow-hidden ${
        viewList ? " tw-translate-x-[-100%]" : " "
      }`}
      style={{ background: "#f5f7fb" }}
    >
      <div className="tw-p-4 tw-flex ">
        <h2 className="tw-text-lg  tw-flex tw-items-center tw-font-medium tw-justify-start ">
          Chats
        </h2>
      </div>
      <div className="tw-flex tw-items-center tw-p-2">
        <div className="tw-w-full">
          <input
            type="text"
            placeholder="Search"
            value={channels.search}
            onChange={(e) => handleSearchFilter(e.target.value)}
            className="tw-w-full tw-flex-grow tw-bg-white tw-p-2 tw-rounded  tw-text-sm tw-outline-none tw-ring-0 tw-border-0 tw-focus:ring-0 tw-focus:border-0"
          />
        </div>
      </div>
      {loadingState.initial && !allChannelsLoaded ? (
        <>
          <div className=" tw-flex tw-justify-center tw-items-center tw-h-screen">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <div
          className="tw-overflow-y-scroll tw-scrollbar-hide tw-flex-grow p-2"
          style={{ scrollbarWidth: "none" }}
        >
          {/* Loader for updates */}
          {loadingState.updating && !allChannelsLoaded && (
            <div className="dotloader m-2">
              <div className="dotloading"></div>
              <div className="dotloading"></div>
              <div className="dotloading"></div>
            </div>
          )}
          {!channels.list.length && (
            <p style={{ textAlign: "center", margin: "2.5rem 0" }}>
              No Conversation Found!
            </p>
          )}
          <>
            {pagination.hasPrevPage && (
              <p
                onClick={() => loadPage(pagination.prevPageFunc)}
                style={{
                  margin: "0.5rem 0",
                  textAlign: "center",
                  fontSize: "0.875rem",
                  cursor: "pointer",
                }}
              >
                Prev
              </p>
            )}
            {(channels.search ? channels.filtered : channels.list).map(
              (curr, ind) => (
                <ChatCard
                  key={ind}
                  data={curr}
                  activeChannel={activeChannel}
                  onSelectChannel={onSelectChannel}
                  setViewList={setViewList}
                  updateUnreadCount={updateUnreadCount}
                  userStatus={channelUserStatuses}
                />
              )
            )}
            {pagination.hasNextPage && (
              <p
                onClick={() => loadPage(pagination.nextPageFunc)}
                style={{
                  margin: "0.5rem 0",
                  textAlign: "center",
                  fontSize: "0.875rem",
                  cursor: "pointer",
                }}
              >
                Next
              </p>
            )}
          </>
        </div>
      )}
    </div>
  );
}

export default ChatList;
