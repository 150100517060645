import React, { useEffect, useState } from 'react'
import Navbar from './../../components/Navbar'
import TopBar from './../../components/topbar'
import { amenityService, userService } from '../../_services'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Footer from './../../layout/footer'
import Pagination from 'react-js-pagination'
import Moment from 'react-moment'
import Tippy from '@tippy.js/react'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import moment from 'moment'
import { Wrapper } from '../Dashboard/Wrapper'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function list(props) {
    const [requesting, setRequesting] = useState(true)
    const [list, setList] = useState([])
    const [sort, setSort] = useState(0)
    const [isSort, setIsSort] = useState(0)
    const [search, setSearch] = useState('')
    const [type, setType] = useState('All')
    const [payoutVerificationStatusType, setPayoutVerificationStatusType] = useState('all')

    const formatDate = (dateString) => {
        return moment(dateString).format('MMMM Do, YYYY hh:mm a');
      };
    const [refreshKey, setRefreshKey] = useState(0)
    var limit = 10
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    const exportUsersData = async () => {
        try {
            await userService.exportUsersData(type, search)
        } catch (error) {
            toast.error('Failed to export')
        }
    }

    const handleStatus = (id, status) => {
        confirmAlert({
            title: 'Are you sure',
            message: `You want to ${status == 'Active' ? 'activate' : 'deactivate'} this user ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        userService.updateStatus({ id: id, status: status }).then(
                            objS => {
                                if (objS.status) {
                                    toast.success('objS.message')
                                    setRefreshKey(oldKey => oldKey + 1)
                                } else {
                                    toast.error('Something went wrong')
                                }
                            },
                            error => {
                                console.log(error)
                            }
                        )
                    },
                },
                {
                    label: 'No',
                    onClick: () => console.log('Click No'),
                },
            ],
        })
    }

    const handleVerifyAirlineEmail = id => {
        userService.verifyAirlineEMail({ userId: id, airlineEmailVerification: 1 }).then(
            objS => {
                if (objS.status) {
                    toast.success('Airline email address verification status updated.')
                    setRefreshKey(oldKey => oldKey + 1)
                } else {
                    toast.error('Something went wrong')
                }
            },
            error => {
                console.log(error)
            }
        )
    }

    const pageChange = page => {
        setPage(page)
        setRequesting(true)
        setRefreshKey(oldKey => oldKey + 1)
    }

    const handleSearch = e => {
        setPage(1)
        setSearch(e.target.value)
        setRequesting(true)
        setRefreshKey(oldKey => oldKey + 1)
    }
    const handleSelect = e => {
        setPage(1)
        setRequesting(true)
        setType(e.target.value)
        setRefreshKey(oldKey => oldKey + 1)
    }
    const handleSort = e => {
        setSort(!sort)
        setIsSort(1)
        setPage(1)
        setRequesting(true)
        setType(e.target.value)
        setRefreshKey(oldKey => oldKey + 1)
    }
    const getSortIcon = () => {
    if (isSort == 1 && sort == 1) {
        return "▲"; // Ascending
    } else if (isSort == 1 && sort == 0) {
        return "▼"; // Descending
    }
    return "⬍"; // No sort
    };

    const handleUserStatus = async (id, status) => {
        try {
            const { data } = await userService.updateStatus({ id, status })
            console.log(data)
            setRefreshKey(e => e + 1)
        } catch (error) {
            console.log(error)
        }
    }


    const handlePayoutVerificationStatus=(statusType)=>{
       setPayoutVerificationStatusType(statusType)
       setRefreshKey(e => e + 1)
    }

    useEffect(() => {
        setRequesting(true)
        userService.userListWithPagination({ search: search.trim(), type, page,payout_verification:payoutVerificationStatusType  , isSort ,sort }).then(
            objS => {
                console.log(objS.data.list)
                setList(objS.data.list)
                setTotal(objS.data.total)
                setRequesting(false)
            },
            error => {
                console.log(error)
            }
        )
    }, [refreshKey])
    return (
        <Wrapper
            type={type}
            showUserFilter={true}
            showDownload={true}
            handleSelect={handleSelect}
            handleSearch={handleSearch}
            handlePayoutVerificationStatus={handlePayoutVerificationStatus}
            showSearch={true}
            search={search}
            exportUsersData={exportUsersData}
            title="User List"
        >
            <ToastContainer />
            {/* <TopBar
        type={type}
        showUserFilter={true}
        handleSelect={handleSelect}
        showSearch={true}
        handleSearch={handleSearch}
        search={search}
      /> */}
            <div className="row">
                <div className="col-xl-12">
                    <div className="tab-content">
                        <div className="tab-pane active show" id="All">
                            <div className="table-responsive">
                                {requesting && (
                                    <div className="tw-my-10 tw-flex tw-items-center tw-justify-center">
                                        <div className="bg-white tw-w-max rounded shadow-md p-4">
                                            <div className="loader"></div>
                                        </div>
                                    </div>
                                )}
                                <table
                                    className="table card-table  display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                                    id="guestTable-all"
                                >
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>User ID</th>
                                            <th>Name</th>
                                            <th>Personal Email Address</th>
                                            <th>Airline Email Address</th>
                                            <th>Phone Number</th>
                                            <th>Created At</th>
                                            <th>Crew Type</th>
                                            <th style={{ cursor: "pointer" }} onClick={handleSort}>Last Logged In {getSortIcon()} </th>
                                            <th>User Type</th>
                                            <th>Account Status</th>
                                            <th>Personal Email Verification Status</th>
                                            <th>Airline Verification Status</th>
                                            <th>Phone Number Verification Status</th>
                                            <th>ID Verification Status</th>
                                            <th>Gmail Authentication Status</th>
                                            <th>Facebook Authentication Status</th>
                                            <th>Apple Authentication Status</th>
                                            <th>Payout Verification Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list.map(user => (
                                            <tr key={user.id}>
                                                <td className="flex  tw-space-y-1 tw-flex-col">
                                                    {user.status === 'Active' ? (
                                                        <li
                                                            onClick={() => handleUserStatus(user.id, 'Inactive')}
                                                            className="tw-cursor-pointer tw-text-center tw-rounded-md tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
                                                        >
                                                            {' '}
                                                            Deactivate
                                                        </li>
                                                    ) : (
                                                        <li
                                                            onClick={() => handleUserStatus(user.id, 'Active')}
                                                            className="tw-cursor-pointer tw-text-center tw-rounded-md tw-p-2 tw-px-4 p-2 tw-text-xs tw-bg-primary tw-text-white"
                                                        >
                                                            Activate
                                                        </li>
                                                    )}
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.id}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.name}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.personalEmail}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.airlineEmail}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.phone}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16">
                                                        <Moment format="MMMM Do, YYYY">{user.createdAt}</Moment>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.crewType}</span>
                                                </td>
                                                <td>
                                                <span className="fs-16">{user.userSession && user.userSession[0] ?formatDate( user.userSession[0].updatedAt) : ' '}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.type}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.status}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16">
                                                        {' '}
                                                        {user.personalEmailVerification == 1 ? 'Verified' : 'Not Verified'}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.airlineEmailVerification == 1 ? 'Verified' : 'Not Verified'}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.phoneVerification == 1 ? 'Verified' : 'Not Verified'}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.idVerificationStatus == 1 ? 'Verified' : 'Not Verified'}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.googleAuthentication == 1 ? 'Verified' : 'Not Verified'}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.facebookAuthentication == 1 ? 'Verified' : 'Not Verified'}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16"> {user.appleAuthentication == 1 ? 'Verified' : 'Not Verified'}</span>
                                                </td>
                                                <td>
                                                    <span className="fs-16">
                                                        {' '}
                                                        {user.payoutVerification == 1
                                                            ? 'Verified'
                                                            : user.stripeInReview
                                                            ? 'In Review'
                                                            : 'Not Verified'}
                                                    </span>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Tippy
                                                            interactive
                                                            theme="light"
                                                            className="tw-bg-white "
                                                            arrow={false}
                                                            trigger="mouseenter"
                                                            content={
                                                                <div className="">
                                                                    <ul className=" tw-bg-white tw-shadow-md tw-p-2 tw-m-4">
                                                                        {user.status == 'Active' && (
                                                                            <li className="tw-text-sm tw-text-gray-700">
                                                                                <a
                                                                                    className="tw-block tw-p-2"
                                                                                    id={user.id}
                                                                                    key={user.id}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={e => {
                                                                                        handleStatus(e.target.id, 'Inactive')
                                                                                    }}
                                                                                >
                                                                                    Deactivate
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                        {user.status == 'Inactive' && (
                                                                            <li className="tw-text-sm tw-text-gray-700">
                                                                                <a
                                                                                    className="tw-block tw-p-2"
                                                                                    id={user.id}
                                                                                    key={user.id}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={e => {
                                                                                        handleStatus(e.target.id, 'Active')
                                                                                    }}
                                                                                >
                                                                                    Activate
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                        {user.airlineEmail && user.airlineEmailVerification == 0 && (
                                                                            <li className="tw-text-sm tw-text-gray-700">
                                                                                <a
                                                                                    className="tw-block tw-p-2"
                                                                                    id={user.id}
                                                                                    key={user.id}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={e => {
                                                                                        handleVerifyAirlineEmail(e.target.id)
                                                                                    }}
                                                                                >
                                                                                    Verify Airline Email
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            }
                                                        >
                                                            <div>
                                                                <BiDotsHorizontalRounded className="tw-text-gray-500 tw-cursor-pointer" size={24} />
                                                            </div>
                                                        </Tippy>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Pagination activePage={page} itemsCountPerPage={limit} totalItemsCount={total} pageRangeDisplayed={10} onChange={pageChange} />
            <Footer />
        </Wrapper>
    )
}
